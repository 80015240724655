import React from "react"
import styled from "@emotion/styled"

const StyledContactDetails = styled.div`
  display: grid;
  margin-bottom: 1rem;
  p {
    font-size: 1rem;
    color: #4f4e4e;
    margin: 0;
  }
  b {
    font-family: "Akzidenz Grotesk";
    font-weight: 600;
    color: black;
  }
`

const ContactDetails = () => (
  <StyledContactDetails>
    <div>
      <p>
        <b>Head Office & Factory</b>
      </p>
      <p>Taman Tekno Blok J2/20 BSD, Tangerang</p>
    </div>
    <div>
      <p>
        <b>Phone</b> 021 7587 4672, 021 7563968/78
      </p>
      <p>
        <b>Fax</b> 021 7587 4673
      </p>
      <p>
        <b>Email</b> marketing@bmrlabel.com
      </p>
    </div>
  </StyledContactDetails>
)

export default ContactDetails
