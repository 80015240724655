import React, { useState } from "react"
import styled from "@emotion/styled"
import axios from "axios"

import ContactDetails from "./ContactDetails"
import Maps from "./Maps"

const StyledContact = styled.div`
  padding-top: 150px;
  padding-bottom: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 5fr 4fr;
  grid-gap: 4rem;
  min-height: 100vh;
  font-size: 1vw;
  line-height: 1.5vw;
  p {
    margin: 0;
  }
  li {
    margin: 0;
  }
  h1 {
    margin: 0;
  }
`

const Left = styled.div`
  display: grid;
  grid-gap: 2rem;
`

const Right = styled.div`
  ul {
    list-style: disc inside none;
    padding: 0;
    li {
      color: #4f4e4e;
      padding: 0.5rem 0;
    }
  }
`

const LeftHeading = styled.h1`
  font-size: 1.8vw;
  font-weight: 700;
  font-family: "Akzidenz Grotesk Extended";
  color: black;
`

const GetInTouch = styled.div`
  margin-bottom: 2vw;
`

const RightHeading = styled.h1`
  font-size: 2vw;
  font-family: "Akzidenz Grotesk Extended";
  font-weight: 300;
  color: black;
  margin-bottom: 2vw;
`

const FormHeading = styled.h1`
  font-size: 1.5vw;
  font-family: "Akzidenz Grotesk Extended";
  font-weight: 600;
  color: black;
`

const ContactForm = styled.form`
  .form-section {
    margin-bottom: 2vw;
    h1 {
      margin-bottom: 1vw;
    }
  }
`

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
`

const TextInput = styled.input`
  border: 1px solid darkgrey;
  padding: 0.5rem;
  width: 49%;
  font-size: 0.8vw;
`

const TextArea = styled.textarea`
  border: 1px solid darkgrey;
  padding: 0.5rem;
  width: 100%;
  resize: none;
  height: 10rem;
  font-size: 0.8vw;
`

const SubmitButton = styled.button`
  color: white;
  padding: 1rem 1.5rem;
  font-size: 1vw;
  border: none;
  font-family: "Akzidenz Grotesk Extended";
  background-color: lightgrey;
  cursor: pointer;
  transition: background-color 0.05s;
  &:hover {
    background-color: grey;
  }
`

const Required = styled.h1`
  display: inline;
  font-size: 1rem;
  font-family: "Akzidenz Grotesk Extended";
  font-weight: 600;
  color: red;
`

const LeftText = styled.p`
  letter-spacing: 0.5px;
  width: 80%;
  color: #4f4e4e;
`

const Line = styled.div`
  background-color: grey;
  height: 1px;
  width: 3rem;
`

const SubjectButton = styled.button`
  background-color: white;
  background-color: ${props => props.active && "black"};
  color: black;
  border: 1px solid darkgrey;
  border: ${props => props.active && "black"};
  width: 100%;
  margin-bottom: 1.5vw;
  text-align: start;
  padding: 0.5rem;
  font-size: 0.8;
  color: darkgrey;
  color: ${props => props.active && "white"};
  cursor: pointer;
  button:focus {
    outline: 0;
  }
`

const Contact = () => {
  const [values, setValues] = useState({
    name: "",
    companyName: "",
    email: "",
    phone: "",
    address: "",
    message: "",
  })
  const [selectedSubject, setSelectedSubject] = useState(0)
  const handleNameChange = e => setValues({ ...values, name: e.target.value })
  const handleCompanyChange = e =>
    setValues({ ...values, companyName: e.target.value })
  const handleEmailChange = e => setValues({ ...values, email: e.target.value })
  const handlePhoneChange = e => setValues({ ...values, phone: e.target.value })
  const handleAddressChange = e =>
    setValues({ ...values, address: e.target.value })
  const handleMessageChange = e =>
    setValues({ ...values, message: e.target.value })

  const handleSubmit = async event => {
    event.preventDefault()
    try {
      const result = await axios.get(
        "https://script.google.com/macros/s/AKfycbz1FyDf5tZplEw9Wk7YaD3UKdsTI_XNtSCr0AtZSrYBJLON3Rw/exec",
        {
          params: {
            name: values.name,
            company_name: values.companyName,
            email: values.email,
            phone: values.phone,
            address: values.address,
            message: values.message,
          },
        }
      )
      console.log(result)
      alert("Terima Kasih!")
      window.location.reload()
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <StyledContact>
      <Left>
        <LeftHeading>FOR A PROJECT, AS A PARTNER OR AS A TEAM</LeftHeading>
        <LeftText>
          Get in touch with us for any inquiries. Feel more than welcome to
          contact us directly through email or phone call!
        </LeftText>
        <Line />
        <ContactDetails />
        <Maps />
      </Left>
      <Right>
        <GetInTouch>
          <RightHeading>Get in touch</RightHeading>
        </GetInTouch>
        <ContactForm onSubmit={handleSubmit}>
          <div className="form-section">
            <FormHeading>
              Name<Required>*</Required>
            </FormHeading>
            <InputWrapper>
              <TextInput
                type="text"
                placeholder="Name"
                name="name"
                autoComplete="off"
                onChange={handleNameChange}
                value={values.name}
                required
              />
              <TextInput
                type="text"
                placeholder="Name of your company"
                name="company_name"
                autoComplete="off"
                onChange={handleCompanyChange}
                value={values.companyName}
                required
              />
            </InputWrapper>
          </div>
          <div className="form-section">
            <FormHeading>
              Info<Required>*</Required>
            </FormHeading>
            <InputWrapper>
              <TextInput
                type="text"
                placeholder="Email"
                name="email"
                autoComplete="off"
                onChange={handleEmailChange}
                value={values.email}
                required
              />
              <TextInput
                type="text"
                placeholder="Phone"
                name="phone"
                autoComplete="off"
                onChange={handlePhoneChange}
                value={values.phone}
                required
              />
            </InputWrapper>
            <TextInput
              type="text"
              placeholder="Address"
              name="address"
              autoComplete="off"
              onChange={handleAddressChange}
              value={values.address}
            />
          </div>
          <div className="form-section">
            <FormHeading>Subject</FormHeading>
            <SubjectButton
              active={selectedSubject === 0}
              onClick={() => setSelectedSubject(0)}
              type="button"
            >
              Request our service for our project
            </SubjectButton>
            <SubjectButton
              active={selectedSubject === 1}
              onClick={() => setSelectedSubject(1)}
              type="button"
            >
              Working with us as a vendor
            </SubjectButton>
            <SubjectButton
              active={selectedSubject === 2}
              onClick={() => setSelectedSubject(2)}
              type="button"
            >
              Join the team BMR labels
            </SubjectButton>
          </div>

          <div className="form-section">
            <FormHeading>Your Message</FormHeading>
            <TextArea
              placeholder="Type your request here"
              name="message"
              autoComplete="off"
              onChange={handleMessageChange}
              value={values.message}
            />
          </div>
          <SubmitButton type="submit">Send Message</SubmitButton>
        </ContactForm>
      </Right>
    </StyledContact>
  )
}

export default Contact
