import React from "react"
import { useMediaQuery } from "react-responsive"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/Contact"
import ContactMobile from "../components/ContactMobile"

const ContactPage = ({ location }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 900px)" })
  const isMobile = useMediaQuery({ query: "(max-width: 900px)" })
  return (
    <Layout location={location}>
      <SEO title="Home" keywords={[`BMR`, `labels`]} />
      {isDesktop && <Contact />}
      {isMobile && <ContactMobile />}
    </Layout>
  )
}

export default ContactPage
