import React from "react"
import styled from "@emotion/styled"

const StyledMaps = styled.div`
  position: relative;
  text-align: right;
  height: auto;
  width: auto;

  .gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: auto;
    width: auto;
  }
`

const Maps = () => (
  <StyledMaps className="mapouter">
    <div className="gmap_canvas">
      {/* <iframe
        title="map"
        width="100%"
        height="400px"
        id="gmap_canvas"
        src="https://maps.google.com/maps?q=7per8&t=&z=13&ie=UTF8&iwloc=&output=embed"
        frameBorder="0"
        scrolling="yes"
        marginHeight="0"
        marginWidth="0"
      /> */}
      <iframe
        title="map"
        width="100%"
        height="400px"
        id="gmap_canvas"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.4360488444586!2d106.67870255032464!3d-6.33752329539118!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69e556671e8af3%3A0x98ea691fe8d6ff30!2sPT.Bahtera%20Mitra%20Rajawali!5e0!3m2!1sen!2sid!4v1585311452117!5m2!1sen!2sid"
        frameBorder="0"
        scrolling="yes"
        marginHeight="0"
        marginWidth="0"
      ></iframe>
    </div>
  </StyledMaps>
)

export default Maps
